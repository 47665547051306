import React from 'react';
import { Link } from 'gatsby';

import Seo from 'components/shared/Seo';

export default ({ pageContext: { pokemon } }) => (
  <>
    <Seo title={pokemon.name} />
    <div style={{ width: 960, margin: '4rem auto' }}>
      <h1>{pokemon.name}</h1>

      <Link to="/">Back to all Pokémon</Link>
    </div>
  </>
);
